import logo from '../../assets/bincher-app-icon.png'
import appStoreLogo from '../../assets/apple-store-white.png'
import googlePlayLogo from '../../assets/google-play-white.png'
import bincherHomeScreenshot from '../../assets/bincher-home-screenshot.png'
import './HomePage.css'
import { APPLE_STORE_APP_URL, GOOGLE_PLAY_APP_URL } from '../../utils/const'

export const HomePage = () => {
    return (
        <main id='home' className='center'>
            <div className='container'>
                <div className='text-container'>
                    <img
                        className="app-logo"
                        src={logo}
                        alt="Bincher logo"
                    />
                    <h1>bincher</h1>
                    <h4>Organiser ses soirées est devenu <br />si simple</h4>
                    {/* <div className='app-store-logos'>
                        <a target='_blank' href={APPLE_STORE_APP_URL}>
                            <img
                                className="app-store-logo"
                                src={appStoreLogo}
                                alt="Bincher logo"
                            />
                        </a>
                        <a target='_blank' href={GOOGLE_PLAY_APP_URL}>
                            <img
                                className="app-store-logo"
                                src={googlePlayLogo}
                                alt="Bincher logo"
                            />
                        </a>
                    </div> */}
                </div>
                {/* <img
                    className="home-screenshot"
                    src={bincherHomeScreenshot}
                    alt="Bincher screenshot"
                /> */}
            </div>
        </main>
    )
}

export default HomePage
