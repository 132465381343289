/**
 * Inputs error messages
 */
export const ERROR_FIELD_REQUIRED = "Champ requis"
export const ERROR_FORMAT_INVALID = "Le format est invalide"
export const ERROR_TOO_SHORT = "Trop court"
export const ERROR_TOO_LONG = "Trop long"
export const ERROR_DO_NOT_CONTAIN_NUMBERS = "Ne peut pas contenir de chiffres"
export const ERROR_PASSWORD_NOT_SIMILAR = "Les mots de passe ne correspondent pas"
export const ERROR_MAIL_PASSWORD_INCORRECT = "Email ou mot de passe incorrect"
export const ERROR_MAIL_ALREADY_EXIST = "Cette adresse email n'est pas disponible"
export const ERROR_PICK_DATE = "Vous devez sélectionner la date et l'heure"
export const ERROR_ENDTIME_NOT_AFTER_STARTTIME = "L'heure ou la date de fin ne peut être définie avant l'heure ou la date de début"
export const ERROR_PASSWORD_INCORRECT = "Le mot de passe n'est pas valide"
export const ERROR_QUANTITY_INVALID = "La quantité n'est pas valide"