import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const ExpoRedirectionPage = () => {

    let { eventCode } = useParams();

    useEffect(() => {
        window.location.href = `exp://176.181.153.91:19000`
    }, [eventCode])

    return <></>
}

export default ExpoRedirectionPage