import Markdown from 'react-markdown'
import GoBack from '../../components/GoBack/GoBack'
import { TERMS_OF_SERVICE_MD } from '../../assets/legal/termsOfService'

export const TermsOfServicePage = () => {
    return (
        <main className='center content'>
            <div className='wrapper'>
                <GoBack />
                <h1>Conditions générales d'utilisation</h1>
                <Markdown>{TERMS_OF_SERVICE_MD}</Markdown>
            </div>
        </main>
    )
}

export default TermsOfServicePage