export const PRIVACY_POLICY_MD: string = `
Date d'entrée en vigueur : 20/04/2024

# Introduction

La présente politique de confidentialité a été élaborée pour vous informer de manière claire et transparente sur la manière dont nous collectons, utilisons, protégeons et partageons vos données personnelles lorsque vous utilisez notre application mobile Bincher (ci-après dénommée "l'Application"). Elle définit également vos droits en matière de protection des données et les moyens de les exercer.

## Définition

- **Données personnelles :** Les "données personnelles" désignent toutes les informations relatives à une personne physique identifiée ou identifiable. Cela inclut, entre autres, des informations telles que votre nom, votre adresse e-mail, votre numéro de téléphone, votre adresse IP, etc.
- **Traitement :** Le "traitement" des données personnelles englobe toutes les opérations effectuées sur ces données, qu'il s'agisse de leur collecte, de leur enregistrement, de leur organisation, de leur stockage, de leur utilisation, de leur modification, de leur transmission ou de leur suppression.
- **Consentement :** Le "consentement" est une manifestation de volonté, libre, spécifique, éclairée et univoque par laquelle vous acceptez que vos données personnelles soient traitées pour les finalités spécifiques indiquées dans cette politique.
- **Application :** L'"Application" représente l'application mobile Bincher développée par l'entreprise Numeraven et accessible depuis un magasin d'applications mobile. Elle inclut toutes les fonctionnalités et services disponibles via cette application.
- **Utilisateur ou "vous" :** L'"Utilisateur" ou "vous" représente une personne physique qui utilise l'Application.
- **Événement :** L'"Événement" caractérise un événement organisé par un organisateur au sein de l'Application. Il englobe plusieurs fonctionnalités liées à la gestion d'événements, notamment la création, la gestion d'une liste d'invités et d'autres fonctionnalités associées à l'organisation d'événements.

# Identité du responsable de traitement

Vos données à caractère personnel sont collectées et traitées par Numeraven, qui est le responsable du traitement des données collectées sur l'Application.

Pour toute question relative à la gestion et à l'utilisation faite de vos données à caractère personnel, Vous pouvez nous joindre par e-mail à [contact@numeraven.com](mailto://contact@numeraven.com).

Numeraven est déterminé à protéger vos données personnelles et à respecter votre vie privée. Si Vous avez des préoccupations ou des demandes concernant vos données, nous sommes là pour Vous aider.

Le respect de la confidentialité de vos données est une priorité pour Numeraven. Nous sommes le point de contact principal pour toutes les questions liées à la protection de vos données personnelles.

# Collecte et utilisation des données

Nous collectons et traitons vos données personnelles dans le cadre de notre Application pour les finalités suivantes :

- **Authentification à l'Application :** Collecte de l'adresse e-mail et du mot de passe pour permettre la création de comptes et l'authentification.
- **Gestion des profils Utilisateurs :** Collecte du nom, du prénom et d'une image de profil (facultative) pour faciliter l'identification par d'autres Utilisateurs au sein d'un Événement. Il est important de noter que les profils des utilisateurs, y compris les noms et prénoms, peuvent être consultés par d'autres membres d'un Événement. Concernant les images de profils et les images des Evénements, Vous devez savoir qu'elles sont accessibles via un lien public et que n'importe qui possédant ce lien peut accéder à l'image.
- **Communication par e-mail :** Utilisation de l'adresse e-mail pour envoyer des liens de réinitialisation de mot de passe et confirmer les changements d'adresse e-mail.
- **Collecte de données de localisation :** Collecte de données de localisation (avec consentement) pour afficher la position de l'Utilisateur sur une carte.
- **Collecte de coordonnées GPS :** Collecte et stockage des coordonnées de l'Événement définies par l'Organisateur, converties en adresse et affichées dans le cadre de l'Événement pour faciliter la localisation.
- **Collecte des données de connexion :** Les adresses IP et les logs sont collectés dans le but de maintenir la sécurité et la stabilité de notre système, de détecter et prévenir les fraudes, ainsi que pour analyser les tendances d'utilisation de notre Application. Ces données peuvent également être utilisées à des fins d'audit, de dépannage et d'amélioration de nos services.
- **Collecte de l'historique des transactions :** Gestion de l'historique des transactions d'achat des utilisateurs effectués dans l'application mobile. Stockage des transactions d'achat des utilisateurs dans notre base de données afin de permettre à l'application d'afficher un historique détaillé des achats pour chaque utilisateur. Collecte anonyme des transactions d'achat par le sous-traitant RevenueCat pour faciliter la gestion des achats intégrés sur les plateformes iOS et Android.

# Consentement

Le consentement des utilisateurs est essentiel pour la collecte et le traitement de leurs données personnelles. Nous nous engageons à obtenir un consentement éclairé et volontaire avant de collecter vos données personnelles. Voici comment nous recueillons votre consentement.

## Processus de consentement

Consentement explicite : Lors de la création de votre compte Utilisateur, Vous serez invité à lire et à accepter notre politique de confidentialité, qui explique en détail comment nous collectons, utilisons et protégeons vos données personnelles. En acceptant notre politique de confidentialité, Vous donnez votre consentement explicite à la collecte et au traitement de vos données personnelles conformément à cette politique.

Options de consentement : Nous ne collectons que les données personnelles nécessaires aux finalités spécifiques de l'Application.

Consentement pour la géolocalisation : Si Vous acceptez de partager votre emplacement pour des fonctionnalités spécifiques, nous Vous demanderons un consentement distinct pour la collecte de données de localisation. Vous pouvez également révoquer ce consentement à tout moment dans les paramètres de l'Application.

## Retrait du consentement

Nous respectons votre droit à contrôler vos données personnelles et à retirer votre consentement à tout moment, lorsque cela est applicable. Si Vous souhaitez retirer votre consentement à tout traitement de données spécifique que Vous avez précédemment autorisé, Vous le pouvez en supprimant votre compte depuis les paramètres de votre compte dans l'Application. Une fois votre compte supprimé, vos données personnelles associées seront effacées ou anonymisées de nos systèmes. Veuillez noter que la suppression de votre compte entraînera l'anonymisation de certaines données associées à votre compte, y compris certaines informations que Vous avez fournies dans les Événements. Nous ne pourrons pas restaurer ces données une fois votre compte supprimé.

# Destinataires des données

Vos données personnelles sont traitées avec la plus grande confidentialité, et nous ne les partageons qu'avec des destinataires spécifiques qui sont soumis à des obligations strictes de protection des données. Voici qui sont nos principaux destinataires de données :

## Service interne qui traite les données

Les personnes spécialisées dans la gestion de l'infrastructure informatique ont accès aux données personnelles dans le cadre de leur travail pour assurer le bon fonctionnement de l'Application.

## Sous-traitants

Nous collaborons avec des sous-traitants de confiance pour fournir certains services essentiels. Nos sous-traitants sont les suivants :

- **SendGrid** **:** Nous utilisons SendGrid pour l'envoi de courrier électronique, ce qui inclus les communications importantes concernant votre compte comme la réinitialisation de votre mot de passe ou le changement d'adresse mail.
- **OVH :** OVH héberge notre infrastructure informatique.
- **Scaleway :** Nous stockons les images de profil Utilisateur et les images des Événements dans un stockage en bloc chez Scaleway.
- **Google Maps et Apple Plans :** Nous utilisons les services de Google Maps et Apple Plans pour les fonctionnalités de l'Application liées à la cartographie et aux conversions des coordonnées GPS en adresses.
- **RevenueCat :** Ce service nous facilite la gestion des achats intégrés sur les plateforme iOS et Android. Ce service dresse un profil client de manière anonyme. Il ne peut faire le lien entre l’utilisateur et ses transactions.

# Durée de conservation des données

Nous ne conservons vos données personnelles que le temps nécessaire pour atteindre les finalités pour lesquelles elles ont été collectées, sauf si la loi applicable nous impose de les conserver plus longtemps. Les durées de conservation varient en fonction du type de données et de leur utilisation, et sont déterminées conformément aux critères suivants :

- **Données du compte Utilisateur :** Vos données de compte, y compris votre nom, prénom, adresse e-mail et autres informations de profil, sont conservées tant que Vous utilisez notre Application. Si Vous décidez de supprimer votre compte, nous anonymiserons vos données de manière sécurisée, sauf si nous sommes tenus de les conserver plus longtemps pour respecter des obligations légales ou contractuelles.
- **Données de localisation :** Si Vous avez donné votre consentement pour la collecte de données de localisation, nous conservons ces données dans votre appareil le temps de votre utilisation de l'Application. Nous ne stockons pas ces données dans notre infrastructure. Vous avez le droit de retirer ce consentement à tout moment dans les paramètres de l'Application dans votre appareil.
- **Données liées aux Événements :** Les données associées aux Événements que Vous avez créés ou auxquels Vous avez participé sont conservées aussi longtemps que l'Événement existe. Nous conservons un historique de l'Événement tant que votre compte Utilisateur existe ou tant que l'Événement n'est pas supprimé par l'Organisateur. Cela Vous permet de revoir les éléments de l'Événement passé à tout moment.
- **Données liées aux données de connexion :** Nous nous engageons à minimiser la conservation des données personnelles et à les supprimer dès qu'elles ne sont plus nécessaires aux fins pour lesquelles elles ont été collectées, sauf si une conservation plus longue est requise par la loi ou nécessaire pour protéger nos intérêts légitimes.
- **Données liées aux transactions utilisateurs :** Vos données de transactions utilisateurs effectuées à travers l’Application sont conservées tant que Vous utilisez notre Application. Si Vous décidez de supprimer votre compte, nous supprimerons vos données de manière sécurisée. Notre sous-traitons conserve

# Vos droits sur vos données personnelles

En tant qu'Utilisateur de notre Application, Vous bénéficiez de certains droits en ce qui concerne vos données personnelles. Nous nous engageons à respecter et à faciliter l'exercice de ces droits. Voici un aperçu de vos droits :

- **Droit d'accès :** Vous avez le droit d'obtenir une confirmation de la manière dont nous traitons vos données personnelles et d'accéder à ces données. Vous pouvez demander une copie de vos données personnelles détenues par nous.
- **Droit de rectification :** Si vos données personnelles sont inexactes ou incomplètes, Vous avez le droit de demander leur rectification ou leur mise à jour. Vous pouvez exercer ce droit directement depuis les paramètres de votre compte dans l'Application pour modifier votre nom, prénom, adresse mail, image de profil et mot de passe.
- **Droit à l'effacement (droit à l'oubli) :** Vous avez le droit de demander la suppression de vos données personnelles dans certaines circonstances, par exemple lorsque les données ne sont plus nécessaires aux fins pour lesquelles elles ont été collectées.
- **Droit à la limitation du traitement :** Dans certaines situations, Vous avez le droit de demander la limitation du traitement de vos données personnelles, par exemple lorsque Vous contestez l'exactitude des données ou lorsque Vous contestez le traitement lui-même.
- **Droit à la portabilité des données :** Vous avez le droit de recevoir vos données personnelles dans un format structuré, couramment utilisé et lisible par machine, et de les transmettre à un autre responsable de traitement, dans la mesure où cela est techniquement possible.
- **Droit d'opposition :** Vous avez le droit de Vous opposer au traitement de vos données personnelles dans certaines circonstances, notamment lorsque le traitement est basé sur un intérêt légitime ou à des fins de marketing direct.
- **Droit de retirer le consentement :** Si Vous avez donné votre consentement pour le traitement de vos données personnelles, Vous avez le droit de retirer ce consentement à tout moment. Cela n'affectera pas la légalité du traitement effectué avant le retrait de votre consentement.
- **Exercice de vos droits :** Pour exercer l'un de ces droits ou poser des questions concernant la protection de vos données personnelles, Vous pouvez nous contacter à l'adresse mail [contact@numeraven.com](mailto://contact@numeraven.com). Nous traiterons votre demande dans les délais prévus par la loi applicable.

# Protection des données personnelles

Nous accordons une importance capitale à la sécurité de vos données personnelles. Pour garantir la protection de vos informations, voici quelques mesures de sécurité que nous avons mis en place :

- L'accès aux données personnelles est strictement limité aux personnes spécialisées dans la gestion de l'infrastructure informatique, qui ont besoin de ces données pour assurer le bon fonctionnement de l'Application.
- Notre base de données n'est pas directement exposée sur Internet, ce qui réduit considérablement les risques d'accès non autorisés.
- Certains de nos sous-traitants nous proposent des mécanismes de détection d'attaques pour renforcer la sécurité de notre infrastructure.
- Tous les échanges de données entre l'Application et nos serveurs, ainsi qu'avec les API tierces, sont chiffrés à l'aide du protocole SSL/TLS, assurant ainsi la confidentialité des informations transmises.
- Nous imposons des politiques de mots de passe robustes pour garantir la sécurité des comptes utilisateurs.

Il est important de noter que bien que nous mettions en œuvre ces mécanismes de sécurité, il est impossible d'éliminer complètement les risques liés à la protection des données. Cependant, notre engagement en matière de sécurité vise à minimiser ces risques et à protéger au mieux vos données personnelles.

En cas de violation de données affectant vos données personnelles, nous Vous notifierons dans les délais prescrits par la loi et prendrons les mesures nécessaires pour minimiser les risques et les impacts potentiels. Cette notification Vous informera de la nature de la violation, des données personnelles concernées, des mesures prises pour remédier à la situation, et des actions recommandées que Vous pouvez entreprendre pour protéger vos informations.

# Transferts hors UE

Nous tenons à Vous informer que nous ne transférons pas vos données personnelles en dehors de l'Union européenne (UE). Toutes les données que nous collectons et traitons dans le cadre de l'Application restent stockées et traitées au sein de l'UE, garantissant ainsi le respect des normes de protection des données de l'UE.

Votre confidentialité et la sécurité de vos données sont de la plus haute importance pour nous. Par conséquent, Vous pouvez avoir l'assurance que vos données personnelles demeurent dans l'UE, conformément à la législation en vigueur en matière de protection des données.

Si à l'avenir, des transferts hors de l'UE devaient devenir nécessaires pour toute raison, nous Vous en informerions de manière transparente et obtenions votre consentement si cela était requis par la réglementation en vigueur.
`