export const TERMS_OF_SERVICE_MD: string = `
Dernière mise à jour : 6 octobre 2024

## Article 1 : Introduction

Les présentes Conditions Générales d'Utilisation (ci-après dénommées "CGU") régissent l'utilisation de l'application mobile Bincher (ci-après dénommée "l'Application") développée et exploitée par Numeraven. Avant de procéder à l'utilisation de l'Application, nous vous invitons à lire attentivement les présentes CGU, car elles établissent les termes et conditions qui régissent votre accès et votre utilisation de l'Application.

En acceptant les présentes CGU, vous reconnaissez avoir pris connaissance de leur contenu et acceptez d'être lié par l'ensemble des dispositions énoncées. Si vous n'acceptez pas ces termes, veuillez ne pas utiliser l'Application.

Les CGU constituent un accord contractuel entre l'utilisateur de l'Application (ci-après dénommé "l'Utilisateur") et Numeraven. Elles définissent les droits et obligations de chaque partie et visent à assurer une utilisation sûre et éthique de l'Application. Veuillez noter que les CGU peuvent être sujettes à des mises à jour périodiques, et nous vous encourageons à consulter régulièrement cette section pour vous tenir informé des changements éventuels.

L'utilisation de l'Application implique automatiquement votre acceptation des présentes CGU. Si vous avez des questions ou des préoccupations concernant ces termes, n'hésitez pas à nous contacter aux coordonnées fournies à la fin de ce document.

## Article 2 : Définitions et Termes clés

- **Application :** Pour les besoins des présentes Conditions Générales d'Utilisation (CGU), le terme "Application" désigne l'application mobile Bincher, développée et exploitée par Numeraven.
- **Utilisateur :** Un "Utilisateur" se réfère à toute personne physique ou morale qui utilise l'Application, qu'il s'agisse de la simple consultation des fonctionnalités ou de l'inscription en tant qu'utilisateur enregistré.
- É**vénement :** Le terme "Événement" fait référence à toute activité organisé par un Utilisateur sur l'Application, créant ainsi une opportunité pour d'autres Utilisateurs de participer après avoir reçu une invitation.
- **Organisateur :** Un "Organisateur" représente un Utilisateur qui crée et gère un Événement sur l'Application. En tant qu'Organisateur, cette personne est responsable de la planification, de la promotion et de la gestion générale de l'Événement.
- **Soirée VIP :** Une “Soirée VIP” désigne un Événement qui a été débloqué pour dépasser les limites des fonctionnalités gratuites et accéder aux fonctionnalités avancées grâce à la dépense de crédits
- **Crédit** : Le terme "Crédit" désigne une unité monétaire virtuelle représentant une valeur dans le solde de l'utilisateur sur l'application Bincher. Ces Crédits permettent à l'Utilisateur de passer un Événement en tant que Soirée VIP afin de bénéficier de fonctionnalités avancées et de lever les limitations associées à un Événement. Chaque Crédit correspond à une valeur spécifique et peut être utilisé exclusivement dans le cadre des services proposés par l'Application.
- **Consentement :** Le "consentement" est une manifestation de volonté, libre, spécifique, éclairée et univoque par laquelle vous acceptez que vos données personnelles soient traitées pour les finalités spécifiques indiquées dans cette politique.

## Article 3 : Acceptation des Conditions

En accédant à l'application, l'utilisateur s'engage expressément à lire les présentes CGU. Si vous n'acceptez pas ces conditions ou ne comprenez pas certains points, veuillez vous abstenir d'utiliser l'application.

Les CGU sont accessibles au moment de votre inscription à l'application et sont également consultables dans la section "Compte" une fois que vous êtes authentifié. Nous vous encourageons à les consulter régulièrement, car elles peuvent être mises à jour périodiquement.

## Article 4 : Description de l'Application

### 4.1 Présentation Générale

Les présentes Conditions Générales d'Utilisation (CGU) régissent l'utilisation de l'application mobile Bincher, développée par Numeraven. Cette application vise à faciliter l'organisation d'Événements en permettant aux utilisateurs de créer des événements, d’indiquer des informations sur ces derniers, d'inviter des participants, de gérer les confirmations de présence et de visualiser ce qu’apportent les invités.

### 4.2 Fonctionnalités Principales

L'application offre les fonctionnalités suivantes :

- **Création d'Événements :** Les utilisateurs peuvent créer des Événements ****en fournissant des détails tels que la date, l'heure, l'emplacement, une brève description et d’autres éléments relatifs à l’organisation d’un Événement.
- **Invitations :** Les organisateurs peuvent inviter des participants en partagent un code unique de l’Événement ou un lien contenant ce dernier qui redirige automatiquement vers l’Application. Les organisateurs peuvent limiter le nombre d’invités d’un Événement.
- **Confirmation de Présence :** Les invités peuvent indiquer leur présence ou leur absence à un Événement.
- **Informations sur l'Événement :** Les utilisateurs peuvent consulter les détails de l'Événement, y compris la liste des invités.
- **Gestion des Nuitées :** Lorsqu'un événement est créé ou mis à niveau en "Soirée VIP" en utilisant des crédits. L'Organisateur peut spécifier le nombre de places disponibles pour les nuitées et permettre aux invités de réserver une place pour dormir sur place. L'accès à cette fonctionnalité est conditionné par l'utilisation des crédits pour la "Soirée VIP".
- **Messagerie dans les Événements :** Lorsqu'un événement est créé ou mis à niveau en "Soirée VIP" en utilisant des crédits, la fonctionnalité de messagerie devient disponible. Cette fonctionnalité permet aux utilisateurs d'échanger des messages ou multimédias liés à l'Événement sans frais additionnels pour chaque message. L'accès à cette fonctionnalité est conditionné par l'utilisation des crédits pour la "Soirée VIP".

### **4.3 Notifications**

- **Notifications de Messagerie :** Lorsque vous recevez des messages via l'Application, les notifications associées à ces messages peuvent être envoyées à votre appareil via les services de notification push de Google (Firebase Cloud Messaging) ou d'Apple (Apple Push Notification Service). Seule la dernière notification de chaque conversation est conservée dans notre base de données pour permettre la prévisualisation et vérifier si la notification a été lue ou non. Ces notifications sont exclusivement stockées dans notre base de données et non par les services de notification push.
- **Notifications Liées aux Événements :** Lors de la création ou de la gestion d'un Événement, des notifications peuvent être générées pour informer les utilisateurs des mises à jour ou changements. Toutes les notifications liées à un Événement sont conservées dans notre base de données pendant la durée de vie de l'Événement. Une fois l'Événement terminé, ces notifications sont supprimées de notre base de données. Les notifications sont également envoyées via les services de notification push de Google et Apple, mais ces services ne conservent pas les données au-delà de la livraison.

## Article 5 : Compte Utilisateur et Sécurité

### 5.1 Création du Compte

- **Création du Compte :** Pour utiliser pleinement les fonctionnalités de l'application Bincher, les utilisateurs doivent créer un compte en fournissant des informations exactes et à jour.
- **Authentification :** Les utilisateurs sont responsables de la confidentialité de leurs informations de connexion, y compris leur nom d'utilisateur et leur mot de passe.

### 5.2 Responsabilités de l'Utilisateur

En créant un compte, l'utilisateur accepte les responsabilités suivantes :

- **Informations Précises :** Fournir des informations personnelles précises et à jour lors de la création du compte.
- **Confidentialité :** Garder les informations de connexion confidentielles et ne pas partager le compte avec d'autres personnes.
- **Notification en Cas d'Utilisation Non Autorisée :** Informer immédiatement Numeraven en cas d'utilisation non autorisée du compte.

### 5.3 Sécurité du Compte

- **Mot de Passe :** Les utilisateurs doivent choisir un mot de passe sécurisé et le mettre à jour régulièrement.

### 5.4 Utilisation Responsable

- **Utilisation Personnelle :** Les comptes sont destinés à un usage personnel et ne doivent pas être partagés avec d'autres personnes.
- **Respect des Droits des Autres :** Les utilisateurs doivent respecter les droits et la vie privée des autres utilisateurs de l'application.

### 5.5 Fermeture de Compte

- **Fermeture Volontaire :** Les utilisateurs ont le droit de fermer leur compte à tout moment en suivant les procédures disponibles dans les paramètres de l'application.
- **Résiliation par Numeraven :** Numeraven se réserve le droit de résilier un compte en cas de non-respect des présentes CGU ou en cas d'utilisation abusive de l'application.

### 5.6 Responsabilité de la Sécurité des Appareils

Les utilisateurs sont responsables de la sécurité de leurs appareils mobiles, y compris la mise à jour régulière des logiciels et la protection contre les logiciels malveillants.

## Article 6 : Présentation du système de crédits

### 6.1 Description

L'Application est accessible gratuitement avec des fonctionnalités de base dans les Événements. Cependant, certaines limites s'appliquent aux Événements organisés. L’Organisateur peuvent choisir de débloquer ces limites et d'accéder à des fonctionnalités avancées d’un Événement en dépensant des Crédits, ce qui fait passer leur Événement en tant que "Soirée VIP".

### 6.2 Création d’une Soirée VIP

Pour créer une Soirée VIP, l'Utilisateur peut le faire au moment de la création d'un Événement ou de la modification d'un Événement existant. Cela peut se faire soit en activant une fonctionnalité avancée, soit en remplissant une des conditions présentées dans la sous-partie "Offres".

### 6.3 Avantages proposés

Voici les avantages de créer une Soirée VIP.

Limites des fonctionnalités gratuites :

- **Nombre d'invités** : Limité à 10 personnes par Événement. Pour les Événements de plus de 10 personnes, il est nécessaire de créer une “Soirée VIP”.
- **Durée de l'**É**vénement** : Les 7 premiers jours de l'Événement sont gratuits. À partir du 8e jour coûte 1 Crédit puis chaque période supplémentaire de 7 jours coûte 1 Crédit.

Fonctionnalités avancées :

Les fonctionnalités avancées (exemple : Nuitée et Messagerie Instantanée), dont la description complète est disponible dans les formulaires de création et de modification d'un Événement, peuvent également être débloquées en créant une Soirée VIP.

### 6.4 Achat de Crédits

L’Utilisateur peut acheter des Crédits depuis la section profil utilisateur et dans les formulaires de création et de modification d'un Événement. L’Utilisateur peut acheter des Crédits via les moyens de paiement natifs des environnements respectifs (Apple Pay pour iOS et Google Pay pour Android). 

### 6.5 Formules d’achat

Deux formules d'achat sont disponibles :

- Achat d'un Crédit unique
- Achat d'un pack de 10 Crédits à un prix plus attractif

### 6.6 Restrictions

Il est strictement interdit de détourner le système de Crédits pour débloquer des fonctionnalités sans payer, par exemple en se générant des Crédits gratuitement via une faille de l'application.

### 6.7 Délai de rétractation

Conformément à la législation en vigueur, l’Utilisateur dispose d'un délai de 14 jours pour exercer leur droit de rétractation.

### 6.8 Procédure de remboursement

Pour les demandes de remboursement, l’Utilisateur doit se rapprocher de leur plateforme respective, Google Play ou Apple Store.

### 6.9 Conséquences des remboursements

En cas de remboursement de la part d'une plateforme, nous supprimerons les Crédits qui ont été achetés du profil de l’utilisateur. Si un crédit remboursé a déjà été dépensé dans un Événement, alors cet Événement sera supprimé de l'Application.

## Article 7 : Utilisation de l'Application

### 7.1 Utilisation Conforme

Les utilisateurs s'engagent à utiliser l'application Bincher conformément aux lois en vigueur et aux présentes Conditions Générales d'Utilisation (CGU). Tout usage contraire à ces règles peut entraîner la résiliation du compte et des actions légales appropriées.

### 7.2 Condition d'Éligibilité

L'utilisation de l'application Bincher est réservée aux individus âgés de 18 ans et plus. En utilisant l'application, les utilisateurs déclarent et garantissent qu'ils satisfont à cette condition d'âge.

### 7.3 Comportements Interdits

Les utilisateurs s'engagent à ne pas :

- **Contenu Inapproprié :** Publier, partager ou diffuser du contenu offensant, illégal, discriminatoire, diffamatoire ou inapproprié.
- **Usurpation d'Identité :** Utiliser l'identité d'une autre personne de manière frauduleuse.
- **Activités Malveillantes :** Participer à des activités malveillantes, y compris la distribution de logiciels malveillants.
- **Contournement du Système de Crédits :** Il est strictement interdit de contourner le système pour se créditer des crédits dans le système premium de l'application.

### 7.4 Respect des Droits des Autres Utilisateurs

Les utilisateurs doivent respecter les droits et la vie privée des autres utilisateurs de l'Application, notamment en évitant toute utilisation abusive des informations personnelles partagées dans le cadre d'un Événement.

### 7.5 **Partage Incontrôlé du Code d'Invitation**

Les utilisateurs reconnaissent que le partage du code d'invitation d'un Événement sur Internet peut entraîner un afflux important de participants. Numeraven décline toute responsabilité pour les conséquences de ce partage, y compris des Événements surpeuplés, et se réserve le droit de prendre des mesures pour gérer ces situations.

### 7.6 Contenu Généré par l'Utilisateur

Les utilisateurs reconnaissent que le contenu généré par l'utilisateur (textes, images, vidéos, etc.) publié sur l'application peut être visible par d'autres utilisateurs et acceptent que Numeraven n'est pas responsable de ce contenu. Les utilisateurs acceptent de ne pas publier de contenu contraire aux présentes CGU.

### 7.7 Signalement des Comportements Inappropriés

Les utilisateurs sont encouragés à signaler tout comportement inapproprié, contenu offensant ou activité suspecte à Numeraven.

### 7.8 Suspension et Résiliation

Numeraven se réserve le droit de suspendre ou de résilier le compte d'un utilisateur en cas de violation des présentes CGU ou de comportement inapproprié, sans préavis.

## Article 8 : Propriété Intellectuelle

### 8.1 Marque Déposée

Le nom de l'application Bincher ainsi que le logo associé sont des marques déposées de Numeraven. Ces marques sont protégées par la législation en vigueur sur la propriété intellectuelle. Toute utilisation non autorisée de ces marques est strictement interdite.

### **8.2 Extraction et Réutilisation des Données**

L’extraction, le transfert permanent ou temporaire, ainsi que la réutilisation de tout ou partie substantielle du contenu de la base de données de l’application Bincher sur un autre support, par tout moyen et sous quelque forme que ce soit, sont strictement interdits. Toute reproduction, extraction ou réutilisation du contenu de l’application sans autorisation préalable expresse de Numeraven est prohibée. L’acceptation des présentes CGU implique la reconnaissance et le respect des droits de propriété intellectuelle de Numeraven par les utilisateurs.

### 8.3 Licence d'Utilisation Limitée

En utilisant l'application Bincher, les utilisateurs reconnaissent et acceptent qu'ils n'acquièrent aucun droit de propriété intellectuelle sur le contenu de l'application. Numeraven accorde aux utilisateurs une licence limitée, non exclusive, révocable et non transférable pour utiliser l'application conformément aux présentes CGU.

### 8.4 Utilisation Autorisée

Les utilisateurs sont autorisés à utiliser le contenu de l'application uniquement dans le cadre des fonctionnalités prévues par l'application. Toute utilisation non autorisée, y compris la reproduction, la modification, la distribution ou la création d'œuvres dérivées, est strictement interdite.

### 8.5 Signalement de Violation des Droits

Numeraven prend au sérieux la protection de ses droits de propriété intellectuelle. Si un utilisateur constate une violation présumée de ces droits, nous encourageons la communication immédiate à **contact@numeraven.com**.

### 8.6 Contenu Généré par l'Utilisateur

En soumettant du contenu généré par l'utilisateur sur l'application, l'utilisateur accorde à Numeraven une licence mondiale, non exclusive, transférable et libre de redevance pour utiliser, reproduire, distribuer et afficher ce contenu dans le cadre de la prestation des services de l'application.

### 8.7 Résiliation en Cas de Violation

Numeraven se réserve le droit de résilier le compte d'un utilisateur en cas de violation répétée des droits de propriété intellectuelle ou des présentes CGU.

## Article 9 : Limitation de Responsabilité

### 9.1 Utilisation de l'Application

En utilisant l'application Bincher, les utilisateurs reconnaissent et acceptent que l'application est fournie "telle quelle" et "telle que disponible". Numeraven ne garantit pas que l'application sera exempte d'erreurs ou d'interruptions, ni que toutes les fonctionnalités seront toujours disponibles.

### 9.2 Responsabilité de Numeraven

Numeraven, y compris ses dirigeants, employés, partenaires et fournisseurs, décline toute responsabilité quant aux dommages directs, indirects, accessoires, spéciaux, consécutifs ou punitifs résultant de l'utilisation ou de l'impossibilité d'utilisation de l'application.

### 9.3 Limitation des Responsabilités

En utilisant l'application, les utilisateurs acceptent expressément que Numeraven ne soit en aucun cas responsable de :

- Pertes de données ou de profits.
- Interruptions de service ou de disponibilité de l'application.
- Contenu généré par l'utilisateur ou actions d'autres utilisateurs.
- Utilisation non autorisée du compte utilisateur.

### 9.4 Force Majeure

Numeraven ne saurait être tenue responsable des retards, des pannes ou des interruptions de service résultant de circonstances indépendantes de sa volonté, telles que des actes de guerre, des catastrophes naturelles, des pannes de réseau ou des événements de force majeure.

### 9.5 Recours Exclusif

Dans tous les cas, le recours exclusif des utilisateurs contre Numeraven sera limité au montant effectivement payé par l'utilisateur pour l'utilisation de l'application au cours des douze (12) derniers mois précédant l'événement à l'origine de la responsabilité.

### 9.6 Modification des Services

Numeraven se réserve le droit de modifier, suspendre ou interrompre tout aspect de l'application à tout moment, y compris la disponibilité des fonctionnalités, sans préavis ni responsabilité envers les utilisateurs.

### 9.7 Législation Applicable

La limitation de responsabilité énoncée dans cette section s'applique dans toute la mesure permise par la législation applicable, indépendamment de toute théorie de responsabilité, qu'elle soit contractuelle, délictuelle ou autre.

## Article 10 : Confidentialité et Données Personnelles

### 10.1 Collecte et Traitement des Données

En utilisant l'application Bincher, les utilisateurs reconnaissent et acceptent la collecte et le traitement de leurs données personnelles par Numeraven, conformément à la Politique de Confidentialité accessible depuis le profil utilisateur.

### 10.2 Conditions d'Utilisation et Politique de Confidentialité

L'utilisation de l'application est soumise à l'acceptation des présentes Conditions Générales d'Utilisation (CGU) et de la Politique de Confidentialité. En acceptant les CGU, les utilisateurs confirment avoir également lu et accepté la Politique de Confidentialité.

### 10.3 Consentement Révocable

Les utilisateurs peuvent révoquer leur consentement à tout moment en désinstallant l'application et en supprimant leur compte, avec des conséquences sur l'utilisation de certaines fonctionnalités.

### 10.4 Sécurité des Données

Numeraven met en œuvre des mesures de sécurité appropriées pour protéger les données personnelles des utilisateurs contre tout accès non autorisé, perte ou divulgation.

### 10.5 Modifications de la Politique de Confidentialité

Numeraven se réserve le droit de mettre à jour la Politique de Confidentialité. Les utilisateurs seront informés des changements de manière appropriée.

### 10.6 Contact

Pour toute question ou préoccupation concernant la confidentialité et la protection des données, veuillez contacter notre équipe à [contact@numeraven.com](mailto:contact@numeraven.com).

## Article 11 : Modifications des CGU

### 11.1 Révisions des Conditions

Numeraven se réserve le droit de mettre à jour ou de réviser les présentes Conditions Générales d'Utilisation (CGU) à tout moment.

### 11.2 Notification des Changements

Les utilisateurs seront informés des modifications via une notification dans l'application ou par tout autre moyen jugé approprié.

### 11.3 Acceptation Continue

L'utilisation continue de l'application après la notification des modifications constitue l'acceptation des nouvelles CGU.

### 11.4 Révision Régulière

Les utilisateurs sont encouragés à consulter régulièrement les CGU pour rester informés des conditions actuelles.

## Article 12 : Résiliation du Compte

### 12.1 Droit de Résiliation

Les utilisateurs ont le droit de résilier leur compte à tout moment depuis la partie Compte de l’Application.

### 12.2 Résiliation par Numeraven

Numeraven se réserve le droit de résilier le compte d'un utilisateur en cas de violation des présentes Conditions Générales d'Utilisation (CGU) ou en cas d'utilisation abusive de l'application, sans préavis.

### 12.3 Conséquences de la Résiliation

La résiliation du compte entraîne la suppression des données associées au compte de l'utilisateur. Numeraven n'est pas responsable de la conservation ou de la restitution ultérieure de ces données.

### 12.4 Suspension Temporaire

Numeraven peut également choisir de suspendre temporairement un compte en cas d'investigation sur des activités suspectes ou de non-conformité aux CGU.

## Article 13 : Dispositions Générales

Le non-exercice, de façon permanente ou temporaire, par l'une des Parties, de son droit d'exiger l'application d'une clause quelconque des présentes CGU ne saurait être interprété comme une renonciation à ladite clause.

En cas de divergence d'interprétation entre l'un des titres figurant en tête des clauses et l'une de ces clauses, les titres seront réputés nuls, et seule la teneur des clauses prévaudra.

## Article 14 : Coordonnées de Contact

Pour toute question, préoccupation ou communication relative aux présentes Conditions Générales d'Utilisation (CGU) de l'application Bincher, veuillez contacter notre équipe aux coordonnées suivantes :

- **Adresse e-mail de Contact :** [contact@numeraven.com](mailto:contact@numeraven.com)

Nous nous efforcerons de répondre à vos demandes dans les plus brefs délais. Merci de prendre contact avec nous pour toute clarification ou assistance supplémentaire.
`