export const DELETE_ACCOUNT_MD = `
Pour supprimer votre compte utilisateur de l'application Bincher et de toutes les données personnelles vous reliant, suivez ces étapes simples :

- Ouvrez l'application mobile.
- Accédez à la section "Compte".
- Trouvez et cliquez sur le bouton "Supprimer mon compte" situé en bas de la page.
- Confirmez votre choix de suppression du compte, le cas échéant.

Veuillez noter que cette action est irréversible. Toutes vos données personnelles seront totalement supprimées et aucune sauvegarde ne sera effectuée. Assurez-vous d'avoir sauvegardé toute information importante avant de procéder à la suppression de votre compte.
`
