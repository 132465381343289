export const LEGAL_DETAILS_MD = `
**Nom de l'entreprise** : Numeraven

**Statut juridique** : Micro-entreprise

**Adresse** : 1 rue des lilas, 21000 Dijon

**Téléphone** : (+33) 06.34.05.28.64

**E-mail** : numeraven@outlook.com

**N° SIRET** : 98388862900018

**Responsable de la publication** : Clément PERREAUT

**Hébergeur** : Ce site est hébergé chez le fournisseur français OVH SAS 2 rue Kellermann - 59100 Roubaix - France - Tél (+33) 09.72.10.10.07

**Icônes et illustrations :**

- [Illustrations par Storyset](https://storyset.com)
- [Icônes d'interface utilisateur par Flaticon](https://www.flaticon.com/uicons)
- [Images par Freepik](https://www.freepik.com)
`