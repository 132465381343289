import './Field.css'

interface FieldProps {
    name: string;
    label: string;
    value: string;
    /**
    * True when user touch the field.
    */
    touched?: boolean,
    type?: 'password';
    errorMessage: string | undefined;
    disabled?: boolean;
    /**
     * Callback called when the text input is blurred / when user exit the field.
     * @param e 
     * @returns {void}
     */
    onBlur?: (e: any) => void
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Field: React.FC<FieldProps> = (props) => {

    return (
        <div className='field'>
            <label htmlFor={props.name}>{props.label}</label>
            <input
                id={props.name}
                name={props.name}
                onChange={props.onChange}
                value={props.value}
                onBlur={props.onBlur}
                type={props.type}
                className={props.touched && props.errorMessage ? 'invalid' : ''}
                disabled={props.disabled}
            />
            {props.errorMessage && <span className='error-msg'>{props.errorMessage}</span>}
        </div>
    )
}

export default Field