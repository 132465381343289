import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CONFIRMATION_EMAIL_PATH, URL_AUTH } from '../../utils/const';

export const ConfirmationSubscriptionPage = () => {

    const [isLoading, setIsLoading] = useState(true)
    const [tokenIsValid, setTokenIsValid] = useState(false)

    let { confirmationEmailToken } = useParams();

    useEffect(() => {

        setIsLoading(true)

        axios.post(`${URL_AUTH}/${CONFIRMATION_EMAIL_PATH}`, {
            confirmationEmailToken: confirmationEmailToken,
        })
            .then(r => {
                console.log(r)
                setTokenIsValid(true)
            })
            .catch(e => {
                console.log(e);
                setTokenIsValid(false)
            })
            .finally(() => setIsLoading(false))

    }, [])

    return (
        <main className='center'>
            <div className='wrapper'>
                {isLoading ?
                    <span className="loader mt25"></span>
                    :
                    tokenIsValid ?
                        <>
                            <h1>Email confirmé !</h1>
                            <p>Votre compte est à présent confirmé. Vous pouvez vous connecter à l'application et accéder à votre compte.</p>
                        </>
                        :
                        <p>Une erreur est survenue. Ce lien ne fonctionne pas ou a expiré.</p>
                }
            </div>
        </main>
    )
}

export default ConfirmationSubscriptionPage