import * as Yup from 'yup';
import { ERROR_FIELD_REQUIRED, ERROR_PASSWORD_INCORRECT, ERROR_PASSWORD_NOT_SIMILAR, ERROR_TOO_LONG, ERROR_TOO_SHORT } from './errorMessages';

export const VALIDATION_PASSWORD_FIELD = Yup.string()
    .required(ERROR_FIELD_REQUIRED)
    .min(8, ERROR_TOO_SHORT)
    .max(24, ERROR_TOO_LONG)
    .matches(/[0-9]/, ERROR_PASSWORD_INCORRECT)
    .matches(/[a-z]/, ERROR_PASSWORD_INCORRECT)
    .matches(/[A-Z]/, ERROR_PASSWORD_INCORRECT)
    .matches(/[!@#$%^&*()_+\-=\[\]{}\\|;':<>.,?/~`]/, ERROR_PASSWORD_INCORRECT)

export const VALIDATION_CONFIRMATION_PASSWORD_FIELD = Yup.string()
    .required(ERROR_FIELD_REQUIRED)
    .oneOf([Yup.ref('password'), ""], ERROR_PASSWORD_NOT_SIMILAR)
    .min(8, ERROR_TOO_SHORT)
    .max(24, ERROR_TOO_LONG)