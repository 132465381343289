import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const JoinEventRedirectionPage = () => {

    let { eventCode } = useParams();

    useEffect(() => {
        // Vérifiez si l'utilisateur est sur un appareil mobile
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        if (isMobile) {
            // Redirigez vers l'URL de l'application mobile
            // window.location.href = `exp+bincher://joinevent?eventCode=${eventCode}` // for development build
            window.location.href = `bincher://joinevent?eventCode=${eventCode}`

        } else {
            console.warn("Réservé aux applications mobile")
        }
    }, [eventCode])

    return <></>
}

export default JoinEventRedirectionPage
