import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from './pages/ErrorPage/ErrorPage';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import JoinEventRedirectionPage from './pages/JoinEventRedirectionPage/JoinEventRedirectionPage';
import ExpoRedirectionPage from './pages/ExpoRedirectionPage/ExpoRedirectionPage';
import ConfirmationSubscriptionPage from './pages/ConfirmationEmailPage/ConfirmationEmailPage';
import PrivacyPolicyPage from './pages/PrivacyPolicy/PrivacyPolicyPage';
import DeleteAccountPage from './pages/DeleteAccountPage/DeleteAccountPage';
import LegalDetailsPage from './pages/LegalDetailsPage/LegalDetailsPage';
import TermsOfServicePage from './pages/TermsOfServicePage/TermsOfServicePage';
import HomePage from './pages/HomePage/HomePage';
import Layout from './App';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "reset-password/:resetPasswordToken",
        element: <ResetPasswordPage />,
      },
      {
        path: "join-event/:eventCode",
        element: <JoinEventRedirectionPage />
      },
      {
        path: "expo",
        element: <ExpoRedirectionPage />
      },
      {
        path: "confirmation-email/:confirmationEmailToken",
        element: <ConfirmationSubscriptionPage />
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicyPage />
      },
      {
        path: "delete-account",
        element: <DeleteAccountPage />
      },
      {
        path: "legal-details",
        element: <LegalDetailsPage />
      },
      {
        path: "terms-of-service",
        element: <TermsOfServicePage />
      }
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <RouterProvider router={router} />
);

