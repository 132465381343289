// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.go-back {
    margin-bottom: 25px;
}

.go-back a {
    text-decoration: none;
    flex-direction: row;
    display: flex;
    align-items: center;
}

.go-back a svg {
    margin-right: 15px;
}

.go-back a:hover {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/GoBack/GoBack.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".go-back {\n    margin-bottom: 25px;\n}\n\n.go-back a {\n    text-decoration: none;\n    flex-direction: row;\n    display: flex;\n    align-items: center;\n}\n\n.go-back a svg {\n    margin-right: 15px;\n}\n\n.go-back a:hover {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
