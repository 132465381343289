// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottom-nav {
    margin-top: 50px;
}

.bottom-nav ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
}


.bottom-nav li a {
    text-decoration: none;
}

.bottom-nav li a:hover {
    text-decoration: underline;
}

.separator {
    width: 100px;
    height: 1px;
    background-color: var(--grey2);
}

@media screen and (min-width: 600px) {
    .bottom-nav ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .bottom-nav li {
        margin: 0 10px 0 10px;
    }

    .separator {
        width: 1px;
        height: 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/FooterPage/FooterPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,UAAU;AACd;;;AAGA;IACI,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI;QACI,aAAa;QACb,mBAAmB;QACnB,uBAAuB;IAC3B;;IAEA;QACI,qBAAqB;IACzB;;IAEA;QACI,UAAU;QACV,YAAY;IAChB;AACJ","sourcesContent":[".bottom-nav {\n    margin-top: 50px;\n}\n\n.bottom-nav ul {\n    list-style-type: none;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin: 0;\n    padding: 0;\n}\n\n\n.bottom-nav li a {\n    text-decoration: none;\n}\n\n.bottom-nav li a:hover {\n    text-decoration: underline;\n}\n\n.separator {\n    width: 100px;\n    height: 1px;\n    background-color: var(--grey2);\n}\n\n@media screen and (min-width: 600px) {\n    .bottom-nav ul {\n        display: flex;\n        flex-direction: row;\n        justify-content: center;\n    }\n\n    .bottom-nav li {\n        margin: 0 10px 0 10px;\n    }\n\n    .separator {\n        width: 1px;\n        height: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
