import React, { Component } from 'react'
import Markdown from 'react-markdown'
import { DELETE_ACCOUNT_MD } from '../../assets/legal/deleteAccount'
import GoBack from '../../components/GoBack/GoBack'

export class DeleteAccountPage extends Component {
    render() {
        return (
            <main className='center'>
                <div className='wrapper'>
                    <GoBack />
                    <h1>Comment supprimer mon compte utilisateur ?</h1>
                    <Markdown>{DELETE_ACCOUNT_MD}</Markdown>
                </div>
            </main>
        )
    }
}

export default DeleteAccountPage