import { Outlet } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage/HomePage';
import FooterPage from './pages/FooterPage/FooterPage';
import MainHeader from './components/SEO/MainHeader';

function Layout() {
    return (
        <MainHeader>
            <header className="App-header"></header>
            <Outlet />
            <FooterPage />
        </MainHeader>
    );
}

export default Layout;