// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
}

.field input {
    border-radius: 15px;
    height: 54px;
    padding-left: 15px;
    margin-top: 14px;
    background-color: var(--black2);
    border: transparent solid 1px;
    color: var(--white);
}

.field:hover {
    cursor: pointer;
}

input:focus {
    border: var(--beige) solid 1px !important;
}

input~span {
    margin-top: 10px;
    color: var(--red);
    font-size: 14px;
}

input.invalid {
    border: var(--red) 1px solid
}

label {
    font-size: 14px;
}

@media screen and (min-width: 600px) {
    .field input {
        height: 62px;
    }
}

/*  */`, "",{"version":3,"sources":["webpack://./src/components/Field/Field.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,+BAA+B;IAC/B,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI;AACJ;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;;AAEA,KAAK","sourcesContent":[".field {\n    display: flex;\n    flex-direction: column;\n    margin-top: 25px;\n}\n\n.field input {\n    border-radius: 15px;\n    height: 54px;\n    padding-left: 15px;\n    margin-top: 14px;\n    background-color: var(--black2);\n    border: transparent solid 1px;\n    color: var(--white);\n}\n\n.field:hover {\n    cursor: pointer;\n}\n\ninput:focus {\n    border: var(--beige) solid 1px !important;\n}\n\ninput~span {\n    margin-top: 10px;\n    color: var(--red);\n    font-size: 14px;\n}\n\ninput.invalid {\n    border: var(--red) 1px solid\n}\n\nlabel {\n    font-size: 14px;\n}\n\n@media screen and (min-width: 600px) {\n    .field input {\n        height: 62px;\n    }\n}\n\n/*  */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
