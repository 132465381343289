import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

const seoData = {
  title: 'Organiser vos événements en toute simplicité - Bincher',
  description: 'Organisez vos soirées entre amis. L’unique application qui centralise toutes les informations essentielles à l’organisation de votre événement privé.',
  url: 'https://bincher.app',
  image: 'https://bincher.app/bincher512.png',
  siteName: 'Bincher',
  locale: 'fr_FR',
};

const structuredData = {
  "@context": "http://schema.org",
  "@type": "SoftwareApplication",
  "name": "Bincher",
  "operatingSystem": "iOS / Android",
  "applicationCategory": "EventManagementApplication",
  "description": seoData.description,
  "image": seoData.image,
  "url": seoData.url,
  "author": {
    "@type": "Organization",
    "name": "Numeraven",
  },
  "publisher": {
    "@type": "Organization",
    "name": "Numeraven",
    "logo": {
      "@type": "ImageObject",
      "url": "URL du logo de l'éditeur"
    },
  },
  "sameAs": [
    "https://x.com/bincher_app",
    "https://www.instagram.com/bincher.app"
  ]
};

interface MainHeaderProps {
  children: ReactNode;
}

const MainHeader: React.FC<MainHeaderProps> = ({ children }) => (
  <>
    <Helmet>
      <title>{seoData.title}</title>
      <meta property="og:title" content={seoData.title} />
      <meta property="og:description" content={seoData.description} />
      <meta property="og:url" content={seoData.url} />
      <meta property="og:image" content={seoData.image} />
      <meta property="og:site_name" content={seoData.siteName} />
      <meta property="og:locale" content={seoData.locale} />

      <meta name="description" content={seoData.description} />
      <link rel="canonical" href={seoData.url} />

      <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
    </Helmet>
    {children}
  </>
);

export default MainHeader;