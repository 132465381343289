import { useEffect, useState } from 'react'
import Field from '../../components/Field/Field'
import './ResetPasswordPage.css'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { VALIDATION_CONFIRMATION_PASSWORD_FIELD, VALIDATION_PASSWORD_FIELD } from '../../utils/inputValidation';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { UPDATE_RESET_PASSWORD_TOKEN_PATH, URL_AUTH, VERIFY_RESET_PASSWORD_TOKEN_PATH } from '../../utils/const';

export const ResetPasswordPage = () => {

    const [isLoading, setIsLoading] = useState(true)
    const [tokenIsValid, setTokenIsValid] = useState(false)
    const [isError, setIsError] = useState(false)
    const [isConfirmed, setIsConfirmed] = useState(false)

    let { resetPasswordToken } = useParams();

    /**
     * Validation of fields and submit form
     */
    const validationSchema = Yup.object({
        password: VALIDATION_PASSWORD_FIELD,
        confirmation: VALIDATION_CONFIRMATION_PASSWORD_FIELD,
    });


    const formik = useFormik({
        initialValues: {
            password: "",
            confirmation: "",
        },
        validationSchema: validationSchema,
        onSubmit: () => {
            setIsLoading(true)
            if (resetPasswordToken !== undefined) {
                handleUpdatePassword(resetPasswordToken, formik.values.password)
            }
        },
        validateOnChange: false,
    });
    /** **/

    const handleUpdatePassword = (resetPasswordToken: string, password: string) => {
        axios.post(`${URL_AUTH}/${UPDATE_RESET_PASSWORD_TOKEN_PATH}`, {
            resetPasswordToken: resetPasswordToken,
            password: password
        })
            .then(r => {
                console.log(r)
                setIsConfirmed(true)
            })
            .catch(e => {
                console.log(e);
                setIsError(true)
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {

        // Check token if valid
        axios.get(`${URL_AUTH}/${VERIFY_RESET_PASSWORD_TOKEN_PATH}?ResetPasswordToken=${resetPasswordToken}`)
            .then(r => {
                if (r.data) {
                    setTokenIsValid(true)
                }
            })
            .catch(e => {
                console.log(e)
                setIsError(true)
            })
            .finally(() => setIsLoading(false))

    }, [])

    return (
        <main className='center'>
            <div className='wrapper'>
                {tokenIsValid ?
                    !isConfirmed ?
                        <>
                            <h1>Réinitialisation du mot de passe</h1>
                            <p className='mt25 text-secondary'>Au minimum 8 caractères, 1 majuscule, 1 minuscule et 1 caractère spécial</p>
                            <div className='field-container'>
                                <form onSubmit={formik.handleSubmit}>
                                    <Field
                                        label='Nouveau mot de passe'
                                        onChange={formik.handleChange("password")}
                                        name='password'
                                        value={formik.values.password}
                                        errorMessage={formik.errors.password}
                                        touched={formik.touched.password}
                                        onBlur={formik.handleBlur("password")}
                                        type='password'
                                    />
                                    <Field
                                        label='Confirmation'
                                        onChange={formik.handleChange("confirmation")}
                                        name='confirmation'
                                        value={formik.values.confirmation}
                                        errorMessage={formik.errors.confirmation}
                                        touched={formik.touched.confirmation}
                                        onBlur={formik.handleBlur("confirmation")}
                                        type='password'
                                    />

                                    <button type='submit' disabled={isLoading}>
                                        {isLoading ? <span className="loader"></span> : "Modifier"}
                                    </button>
                                </form>
                            </div>
                        </>
                        :
                        <>
                            <h1>Confirmation !</h1>
                            <p className='mt25 text-secondary'>Votre modification a bien été prise en compte. Vous pouvez fermer cette page et revenir vers l'application pour vous authentifier avec votre nouveau mot de passe.</p>
                        </>
                    :
                    isLoading ?
                        <span className="loader mt25"></span>
                        :
                        isError ?
                            <p className='mt25'>Une erreur est survenue. Veuillez réessayer plus tard.</p>
                            :
                            <p className='mt25'>Le lien de réinitialisation est invalide ou a expiré.</p>
                }
            </div>
        </main>
    )
}

export default ResetPasswordPage