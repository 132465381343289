
// Authen API url

export const URL_AUTH = `https://staging.bincher.app/auth/v1/Authentication`

export const VERIFY_RESET_PASSWORD_TOKEN_PATH = "verify-reset-password-token"
export const UPDATE_RESET_PASSWORD_TOKEN_PATH = "update-password-with-token"
export const CONFIRMATION_EMAIL_PATH = "confirmation-email"

/**
 * App Id used to redirect user to the app page
 */
export const APPLE_STORE_APP_URL = 'https://apps.apple.com/app/id/6480233814'
export const GOOGLE_PLAY_APP_URL = 'https://play.google.com/store/apps/details?id=com.numeraven.bincher'