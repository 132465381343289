import Markdown from 'react-markdown'
import { PRIVACY_POLICY_MD } from '../../assets/legal/privacyPolicy'
import './PrivacyPolicyPage.css'
import GoBack from '../../components/GoBack/GoBack'

export const PrivacyPolicyPage = () => {
    return (
        <main className='center content'>
            <div className='wrapper'>
                <GoBack />
                <h1>Politique de confidentialité</h1>
                <Markdown>{PRIVACY_POLICY_MD}</Markdown>
            </div>
        </main>
    )
}

export default PrivacyPolicyPage