// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.app-logo {
    width: 80px;
    box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.37);
}

.text-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.app-store-logos {
    margin-top: 15px;
}

.app-store-logos a:first-child {
    padding-right: 20px;
}

.app-store-logo {
    width: 140px;
}

#home h1 {
    margin-top: 15px;
    margin-bottom: 0;
}

#home h4 {
    text-align: center;
    margin-top: 0;
    font-weight: 400;
}

.home-screenshot {
    width: 300px;
    margin-top: 25px;
}

@media screen and (min-width: 600px) {

    main#home {
        height: 100vh;
    }

    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 50px;
    }

    .text-container {
        align-items: flex-start;
    }

    #home h1 {
        font-size: 54px;
    }

    #home h4 {
        text-align: unset;
    }

    .app-store-logo {
        width: 150px;
        margin-top: 75px;
        margin-bottom: 50px;
    }

    .home-screenshot {
        margin-left: 100px;
        width: 450px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/HomePage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,4CAA4C;AAChD;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;;IAEI;QACI,aAAa;IACjB;;IAEA;QACI,aAAa;QACb,mBAAmB;QACnB,mBAAmB;QACnB,iBAAiB;IACrB;;IAEA;QACI,uBAAuB;IAC3B;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,iBAAiB;IACrB;;IAEA;QACI,YAAY;QACZ,gBAAgB;QAChB,mBAAmB;IACvB;;IAEA;QACI,kBAAkB;QAClB,YAAY;IAChB;AACJ","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n\n.app-logo {\n    width: 80px;\n    box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.37);\n}\n\n.text-container {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n}\n\n.app-store-logos {\n    margin-top: 15px;\n}\n\n.app-store-logos a:first-child {\n    padding-right: 20px;\n}\n\n.app-store-logo {\n    width: 140px;\n}\n\n#home h1 {\n    margin-top: 15px;\n    margin-bottom: 0;\n}\n\n#home h4 {\n    text-align: center;\n    margin-top: 0;\n    font-weight: 400;\n}\n\n.home-screenshot {\n    width: 300px;\n    margin-top: 25px;\n}\n\n@media screen and (min-width: 600px) {\n\n    main#home {\n        height: 100vh;\n    }\n\n    .container {\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        margin-left: 50px;\n    }\n\n    .text-container {\n        align-items: flex-start;\n    }\n\n    #home h1 {\n        font-size: 54px;\n    }\n\n    #home h4 {\n        text-align: unset;\n    }\n\n    .app-store-logo {\n        width: 150px;\n        margin-top: 75px;\n        margin-bottom: 50px;\n    }\n\n    .home-screenshot {\n        margin-left: 100px;\n        width: 450px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
