import './FooterPage.css'
import { Link } from 'react-router-dom'

export const FooterPage = () => {
    return (
        <footer>
            <nav className='bottom-nav'>
                <ul>
                    <li><a href='/'>Accueil</a></li>
                    <li><div className='separator' /></li>
                    <li><a href='privacy-policy'>Politique de confidentialité</a></li>
                    <li><a href='terms-of-service'>CGU</a></li>
                    <li><a href='legal-details'>Mentions légales</a></li>
                </ul>
            </nav>
        </footer>
    )
}

export default FooterPage
